<template>
  <v-container fill-height>
    <v-row justify="center" align="center" no-gutters>
      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <v-alert color="red lighten-1" type="error" v-if="alerts" dense>
            {{ alerts }}
          </v-alert>

          <v-alert color="green lighten-1" type="success" v-if="messages" dense>
            {{ messages }}
          </v-alert>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <v-card v-if="token">
            <v-card-text v-if="succeed">
              <v-btn large to="/login" color="primary" class="mr-4">
                Login
              </v-btn>
            </v-card-text>
            <v-card-text v-else>
              <v-card-actions class="justify-center">
                <v-form ref="form" @submit.prevent="handleActivate">
                  <v-progress-circular
                    v-if="waiting"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-btn
                    type="submit"
                    large
                    v-else
                    :disabled="!token"
                    color="primary"
                    class="mr-4"
                  >
                    Activate Account Now
                  </v-btn>
                </v-form>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const axios = require("axios");
const requests = axios.create({
  timeout: 20000,
});

export default {
  data: () => ({
    token: null,
    alerts: null,
    messages: null,
    waiting: false,
    succeed: false,
  }),
  created() {
    this.token = this.$route.query.token;
  },
  methods: {
    handleActivate() {
      this.waiting = true;
      requests
        .post(`/api/auth/activate`, {
          token: this.token,
        })
        .then((response) => {
          this.waiting = false;
          this.alerts = null;
          this.succeed = true;
          this.messages = response.data.message;
        })
        .catch((err) => {
          this.waiting = false;
          this.messages = null;
          if (err.response == null) {
            alert("unknown server error");
            return;
          }
          const data = err.response.data;
          if (err.response.status >= 500) {
            this.alerts = "server error";
          } else if (err.response.status >= 400) {
            if (data != null) {
              this.alerts = data.detail;
            } else {
              this.alerts = "bad request";
            }
          } else {
            this.alerts = "unknown error";
          }
        });
    },
  },
};
</script>
